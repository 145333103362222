import { useRoute } from '#imports';
import {reactive, watch, toValue, type Reactive} from 'vue';

interface defaultGqlVariables {
    site?: String;
    uri?: String;
    [any: string]: any;
}

export const useCraftVariables = (): Reactive<{
    variables: defaultGqlVariables;
}> => {
    const { currentCraftUri, getCurrentCraftUri } = useCraft();
    const route = useRoute();

    const { activeSite } = useMultisite();

    const variables = reactive({
        uri: toValue(currentCraftUri) as String,
        site: activeSite.value.handle,
    });
    watch(() => route.path, () => {
        if (Object.hasOwnProperty.call(variables, 'uri')) {
            variables.uri = getCurrentCraftUri(route.path, toValue(activeSite?.value?.locale)) as String;
        }
        if (Object.hasOwnProperty.call(variables, 'site')) {
            variables.site = activeSite.value.handle;
        }
    });

    return { variables };
};

